import "./App.css";
import { useState, useEffect } from "react";
import cara from "./assets/cara.jpg";
import coroa from "./assets/coroa.jpg";
function App() {
  const [numero, setNumero] = useState(Math.random());
  const [resultado, setResultados] = useState([]);

  function rodarMoeda() {
    setNumero(Math.random());
  }

  function resetar() {
    setResultados([]);
  }

  useEffect(() => {}, [resultado]);

  useEffect(() => {
    if (numero > 0.5) {
      setResultados([...resultado, "Cara"]);
    } else {
      setResultados([...resultado, "Coroa"]);
    }
  }, [numero]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={numero > 0.5 ? cara : coroa} alt="moeda" />
        <button onClick={rodarMoeda}>Rodar Moeda</button>
        <section>
          <p>{resultado.join(", ")}</p>
        </section>
        <button onClick={resetar}>Reset</button>
      </header>
    </div>
  );
}

export default App;
